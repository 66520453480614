import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggProvider } from '@frontegg/react';

//For Dev
const contextOptions = {
  baseUrl: "https://dev.wealthwriteup.ca",
  clientId: 'f3657848-6ce2-45be-8e5a-d639a1d83bea'
};

// Ajout de themeOptions pour le mode plein écran
const themeOptions = {
  adminPortal: {
    layout: {
      fullScreenMode: true
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FronteggProvider contextOptions={contextOptions} themeOptions={themeOptions} hostedLoginBox={true}>
    <App />
  </FronteggProvider>
);

reportWebVitals();
